import React from 'react'
import Modal from 'react-modal';

const OverwriteConfirmModal = ({isOpen, onClose, onConfirm}) => {

  return (
    isOpen && (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        className="custom-modal modal-dialog"
        overlayClassName="modal-overlay">
        <h3 className='modal-title text-center'>Overwrite Field Library</h3>
        <div className='modal-content custom-modal-content'>
          <div className='modal-body'>
            <h3>Are you sure, you want to overwrite the field library?</h3>
          </div>
          <div className='modal-footer' style={{ border: 'none' }}>
            <button
              className='btn btn-danger margin-left-5 margin-top-5'
              onClick={onClose}>
              No
            </button>
            <button
              className='btn btn-success margin-right-5 margin-top-5'
              onClick={onConfirm}>
              Yes
            </button>
          </div>
        </div>
      </Modal>
    )
  )
}

export default OverwriteConfirmModal