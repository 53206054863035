import React, { Fragment, useState } from 'react';
import Form from '../../components/Form/Form';
import useForm from "../../components/Form/useForm";
import { useDispatch } from "react-redux";
import importTemplateSlice from "../../redux/pages/templates/importTemplateSlice";
import { Col, Row } from "react-bootstrap";
import Control from "../../components/Form/Control";
import SubmitButtonGroup from "../../components/Form/SubmitButton";
import { importTemplate } from "../../redux/actions/templateActions";
import SystemMessage from "../../components/SystemMessage";
import { ACCEPTED_MIME_TYPES } from "../../constants/mimeTypes";
import ControlDropZone from "../../components/Controls/ControlDropZone";
import { getFileContentAsText } from "../../components/Controls/dropzoneUtils";
import PropTypes from "prop-types";
import ControlCheckbox from '../../components/Controls/ControlCheckbox';
import OverwriteConfirmModal from './OverwriteConfirmModal';
export const FORM_KEY = 'importTemplate';

const TemplateFields = ({ fields }) => {
    return (
        <ul>
            {fields
                .sort((a, b) => { return a.position - b.position; })
                .map(field => {
                    let fieldName, isJson = false;
                    try {
                        const json = JSON.parse(field.names['EN']);
                        fieldName = json['blocks'][0]['text'];
                        isJson = true;
                    } catch (e) {
                        fieldName = field.names['EN'];
                    }
                    return (
                        <li key={field.code}>
                            <span className={isJson ? 'text-bold' : ''}>{fieldName}</span> ({field.position})
                            {field.defaultValueScript && <span style={{ color: 'red' }}> Value!</span>}
                            {field.nameScript && <span style={{ color: 'red' }}> Name!</span>}
                            {field.listOptionsScript && <span style={{ color: 'red' }}> ListOptions!</span>}
                            {field.positionScript && <span style={{ color: 'red' }}> Position!</span>}
                            {field.customClassesScript && <span style={{ color: 'red' }}> CustomClasses!</span>}
                            {field.visibilityMatrixScript && <span style={{ color: 'red' }}> Visibility!</span>}
                            {field.readOnlyMatrixScript && <span style={{ color: 'red' }}> ReadOnly!</span>}
                            {field.requiredMatrixScript && <span style={{ color: 'red' }}> Required!</span>}
                            {field.helpTextStaffScript && <span style={{ color: 'red' }}> HelpTextStaff!</span>}
                            {field.helpTextReviewerScript && <span style={{ color: 'red' }}> HelpTextReviewer!</span>}
                            {field.helpTextClientScript && <span style={{ color: 'red' }}> HelpTextClient!</span>}
                        </li>
                    );
                })}
        </ul>
    )
};
TemplateFields.propTypes = {
    fields: PropTypes.array
}

const TemplateSections = ({ sections }) => {
    return (
        <ul>
            {sections
                .sort((a, b) => { return a.position - b.position; })
                .map(section => {
                    return (<li key={section.code}>{section.displayName['EN']} ({section.position})</li>);
                })}
        </ul>
    )
};
TemplateSections.propTypes = {
    sections: PropTypes.array
}
const TemplateImportPreview = ({ importJson }) => {
    const { exportVersion, templateName, templateCode, sourceServer, exportTime, creationDateTime,
        sections, fieldInstances } = importJson;
    if (!exportVersion || !templateName || !templateCode || !sourceServer) return (<>
        <span>Invalid template export!</span>
    </>);
    return (
        <>
            <Row>
                <Col md={12}>
                    <h3>Preview</h3>
                </Col>
                <Col md={6}>
                    <dl style={{ padding: 20, fontSize: 18 }}>
                        <dt>Template Name:</dt>
                        <dd>{templateName}</dd>
                        <dt>Template Code:</dt>
                        <dd>{templateCode}</dd>
                        {creationDateTime && (<>
                            <dt>Creation Time:</dt>
                            <dd>{creationDateTime}</dd>
                        </>)}
                    </dl>
                </Col>
                <Col md={6}>
                    <dl style={{ padding: 20, fontSize: 18 }}>
                        <dt>Export Version:</dt>
                        <dd>{exportVersion}</dd>
                        {sourceServer && (<>
                            <dt>Source Server:</dt>
                            <dd>{sourceServer.toUpperCase()}</dd>
                        </>)}
                        {exportTime && (<>
                            <dt>Export Time:</dt>
                            <dd>{exportTime}</dd>
                        </>)}
                    </dl>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col md={12}>
                    <h4>Sections [{sections.length}]</h4>
                    <TemplateSections sections={sections} />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <h4>Fields [{fieldInstances.length}]</h4>
                    <TemplateFields fields={fieldInstances} />
                </Col>
            </Row>
            <dl style={{ padding: 20, fontSize: 18 }}>
            </dl>
        </>
    );
};
TemplateImportPreview.propTypes = {
    importJson: PropTypes.object,
    // sourceServer: PropTypes.string,
    // templateName: PropTypes.string,
    // exportVersion: PropTypes.string,
};

const TemplateImportForm = React.forwardRef((props, ref) => {
    // console.warn('ref', ref);

    const dispatch = useDispatch();
    const formProps = useForm(importTemplateSlice.actions, FORM_KEY);
    const { mappedErrorMessages, serverErrors } = formProps;

    const [isLoading, setIsLoading] = useState(false);
    const [importJson, setImportJson] = useState({});
    const [overwriteFieldLibrary, setOverwriteFieldLibrary] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [formData, setFormData] = useState([]);

    const changeJsonContent = async (fileName, { jsFile }) => {
        // console.log('aaa', fileName, jsFile);
        // const json = await getFileContentAsJson(jsFile);
        setIsLoading(true);
        const textContent = await getFileContentAsText(jsFile);
        // console.log(typeof textContent);
        if (typeof textContent !== 'string') return;
        setImportJson(JSON.parse(textContent))
        setIsLoading(false);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleConfirm() {
        setOpen(false);
        const [data, ...rest] = formData;
        dispatch(importTemplate(data, ...rest));
    }

    return (
        <>
            <Form
                ref={ref}
                formKey={FORM_KEY}
                onSubmit={({ name }, ...rest) => {
                    console.log('2', name, rest, formProps);
                    const data = {
                        templateName: formProps.entity.templateName,
                        data: importJson,
                        overwriteFieldLibrary: overwriteFieldLibrary
                    }
                    if (overwriteFieldLibrary) {
                        setFormData([data, ...rest]);
                        setOpen(true);
                    } else {
                        dispatch(importTemplate(data, ...rest));
                    }
                }}
                {...formProps}
            >
                <div style={{ position: 'relative' }}>
                    <SystemMessage
                        type="error"
                        validationMessages={[...mappedErrorMessages, ...serverErrors]}
                        shown={!isLoading}
                        preventAddClasses
                    />
                    <Row>
                        <Col md={7}>
                            <Control md={12} label="New Template Name" name="templateName" disabled={isLoading} required container={Fragment} />
                            <Control
                                md={12}
                                label="Overwrite field library"
                                name="overwriteFieldLibrary"
                                component={ControlCheckbox}
                                disabled={isLoading}
                                container={Fragment}
                                onChange={() => {
                                    setOverwriteFieldLibrary(!overwriteFieldLibrary)
                                }}
                            />
                        </Col>
                        <Col md={5}>
                            <Control
                                md={12}
                                label="Upload Exported Template"
                                name="template"
                                accept={ACCEPTED_MIME_TYPES.json}
                                component={ControlDropZone}
                                fileName={'test'}
                                required={true}
                                container={Fragment}
                                onChange={changeJsonContent}
                            />
                        </Col>
                    </Row>
                </div>
                <TemplateImportPreview importJson={importJson} />
                {/*            {importJson && <div className={'preview'}>
                    <pre>{JSON.stringify(importJson, null, 2)}</pre>
                </div>}*/}
                <div style={{ padding: 25 }}>
                    <SubmitButtonGroup title="Import" />
                </div>
            </Form>
            <OverwriteConfirmModal
                isOpen={isOpen}
                onClose={handleClose}
                onConfirm={handleConfirm}
            />
        </>
    );
});
TemplateImportForm.displayName = 'TemplateImportForm';
export default TemplateImportForm;
